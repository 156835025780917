import React, { useContext, useEffect } from 'react';
import { LeReacteurContext } from '../../Layout';
import { Paragraph } from 'src/components/Typography';
import colors from 'src/style/colors';
import { navigate } from 'gatsby';
import { NextBtn } from '../../Form/elements';

const SuccessBlock = ({ defaultSelectedFormation, onFormSuccess }) => {
  const { dtlFormValidation } = useContext(LeReacteurContext);
  useEffect(() => {
    if (window) {
      window.scrollTo({
        top: 90,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      return null;
    }
  });
  useEffect(() => {
    dtlFormValidation('estimate');
  }, []);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 330,
      }}
      data-cy="requestSent"
    >
      <Paragraph
        css={{
          textAlign: 'center',
          color: colors.purpleDark,
        }}
      >
        Votre demande de devis a bien été prise en compte.
        <br />
        Nous reviendrons vers vous dans les plus brefs délais.
      </Paragraph>

      <NextBtn
        onClick={() => {
          navigate(defaultSelectedFormation ? `/formation/${defaultSelectedFormation}` : '/');
          onFormSuccess();
        }}
      >
        Fermer
      </NextBtn>
    </div>
  );
};

export default SuccessBlock;
