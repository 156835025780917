import React from 'react';
import SuccessBlock from './blocks/SuccessBlock';
import FirstBlock from './blocks/FirstBlock';
import { FormBlockSection } from 'src/components/Form/elements';
import { useRestoreFormManager } from 'src/utils/RestoreForm';

const FORM_NAME = 'devis-form-completed';

const FormSteps = ({ defaultSelectedFormation, step, setStep }) => {
  const manager = useRestoreFormManager();

  return (
    <FormBlockSection>
      {step === 1 ? (
        <FirstBlock
          onFormSuccess={() => {
            manager.set(FORM_NAME, true);
            setStep(2)
          }}
          defaultSelectedFormation={defaultSelectedFormation}
        />
      ) : step === 2 && (
        <SuccessBlock
          defaultSelectedFormation={defaultSelectedFormation}
          onFormSuccess={() => {
            manager.reset('devis-form');
            manager.reset('devis-form-completed');
          }}
        />
      )}
    </FormBlockSection>
  );
};

export default FormSteps;
