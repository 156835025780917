import React from 'react';
import { Section } from 'src/components/Section';
import Layout from 'src/components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { FormHeader } from 'src/components/Form';
import { FormSteps } from 'src/components/DevisForm/index';
import { mediaQueries } from 'src/style/breakpoints';
import sizes from 'src/style/sizes';

const Devis = ({ location }) => {
  const [step, setStep] = React.useState(1);
  const defaultSelectedFormation =
    location && location.state && location.state.fromFormationPage
      ? location.state.fromFormationPage
      : null;

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2B"
      pageName="Demandez-nous un devis"
      pageType="form"
      formStart={true}
      formType="estimate"
      metaTitle="Demandez-nous un devis | Le Reacteur"
      metaDescription="Vous êtes un professionnel et souhaitez monter en compétences sur JavaScript ? Nous proposons des formations dédiées aux développeurs expérimentés. D'une durée de 1 à 5 jours, nos sessions sont dispensées en inter et intra-entreprises."
    >
      <Section
        theme="primary"
        css={{
          [mediaQueries.phoneOnly]: {
            height: sizes.purpleBgHeightMobile,
          },
          [mediaQueries.tabletPortraitUp]: {
            height: sizes.purpleBgHeightTablet,
          },
          [mediaQueries.desktopUp]: {
            height: sizes.purpleBgHeightDesktop,
          },
        }}
      />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <div
          css={{
            [mediaQueries.phoneOnly]: {
              marginTop: -160,
            },
            [mediaQueries.tabletPortraitUp]: {
              marginTop: -370,
            },
            [mediaQueries.tabletLandscapeUp]: {
              marginTop: -330,
            },
            [mediaQueries.desktopUp]: {
              marginTop: sizes.marginTopContainer,
            },
          }}
        >
          <FormHeader
            mainTitle="Demander un devis"
            subTitle="Formations professionnelles intra-entreprises"
          />
          <FormSteps
            defaultSelectedFormation={defaultSelectedFormation}
            step={step}
            setStep={setStep}
          />
        </div>
      </Section>
    </Layout>
  );
};

export default Devis;
